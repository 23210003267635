import React, { Suspense, lazy } from 'react';
import './App.css';
import { IntlProvider } from 'react-intl';
import English from './lang/en.json';
import Spanish from './lang/es.json';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { changeLanguage } from './redux/actions';
import { useSelector, useDispatch } from 'react-redux';

const Home = lazy(() => import('./components/Home/Home'));
const NotFound = lazy(() => import('./components/NotFound/NotFound'));

const Loading = () => {
  return <div className='loading'></div>;
};

const App = () => {
  const languages = {
    en: English,
    es: Spanish,
  };

  const dispatch = useDispatch();
  let language;
  let currentLanguage = useSelector((state) => state.language.language);

  const setLanguage = () => {
    switch (navigator.language) {
      case 'es-ES':
      case 'es-es':
      case 'es':
        language = 'es';
        break;
      default:
        language = 'en';
    }
    if (!localStorage.getItem('language') || !Object.keys(languages).includes(localStorage.getItem('language'))) {
      localStorage.setItem('language', language);
    } else {
      dispatch(changeLanguage(localStorage.getItem('language')));
    }
  };

  setLanguage();

  return (
    <IntlProvider locale={currentLanguage || localStorage.getItem('language')} messages={languages[currentLanguage || localStorage.getItem('language')]}>
      <Router>
        <Suspense fallback={Loading()}>
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route path='*' component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </IntlProvider>
  );
};

export default App;
