import { createStore } from 'redux';
import rootReducer from './reducers';

let store;

switch (process.env.NODE_ENV) {
  case 'development':
    store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    break;
  case 'production':
    store = createStore(rootReducer);
    break;
  default:
    store = createStore(rootReducer);
}

export default store;
