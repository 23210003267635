const initialState = {
  language: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE': {
      localStorage.setItem('language', action.payload);
      return { ...state, language: (state.language = action.payload) };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
